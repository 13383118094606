import React from 'react';
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { Layout, SEO } from 'components/common';
import { Lead } from '../components/common';

const NotFoundPage = ({ intl }: { intl: any }) => (
  <Layout>
    <SEO
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <h1>
      <FormattedMessage id="notfound.header" />
    </h1>
    <Lead>
      <FormattedMessage id="notfound.description" />
    </Lead>
  </Layout>
)

export default injectIntl(NotFoundPage)